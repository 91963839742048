import React from 'react';

const LikeSvg = ({ isActive }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M19.1002 3.11582C18.2774 2.03303 16.9624 1.40961 15.0833 1.20896C14.9331 1.19255 14.7766 1.18372 14.6151 1.18372C12.9493 1.18372 11.0575 2.01032 9.99874 3.5714C8.94119 2.01032 7.04947 1.18372 5.38491 1.18372C5.22337 1.18372 5.06689 1.19255 4.91671 1.20896C3.03887 1.40961 1.72514 2.03303 0.901061 3.11456C0.084554 4.18725 -0.179202 5.65873 0.118628 7.48987C0.559062 10.1729 4.14437 13.5462 7.02549 16.2569C7.85967 17.0393 8.60424 17.741 9.09011 18.2635C9.09011 18.2635 9.58228 18.8162 10.0416 18.8162C10.501 18.8162 10.978 18.2572 10.978 18.2572C11.4614 17.7334 12.2047 17.0292 13.0363 16.2405C15.891 13.5335 19.4435 10.1666 19.8814 7.48861C20.1792 5.65999 19.9167 4.18851 19.1002 3.11582ZM18.3771 7.24379C18.0225 9.41567 14.3905 12.8596 11.9876 15.135C11.2052 15.8783 10.5338 16.5144 10.0316 17.0419C9.52802 16.5181 8.85411 15.8846 8.07042 15.1464C5.64488 12.8634 1.98006 9.41693 1.62418 7.24379C1.39576 5.84298 1.56108 4.76524 2.11509 4.03833C2.67163 3.30764 3.6421 2.87857 5.08203 2.72334C5.17794 2.71325 5.28016 2.7082 5.38617 2.7082C6.6734 2.7082 8.85916 3.5184 9.25038 5.69028C9.316 6.05247 9.6315 6.31622 10 6.31622C10.3685 6.31622 10.6853 6.05247 10.7496 5.69028C11.1396 3.5184 13.3266 2.7082 14.6151 2.7082C14.7211 2.7082 14.8233 2.71325 14.9218 2.7246C16.3617 2.87857 17.3309 3.30764 17.89 4.0396C18.4414 4.76398 18.6055 5.84298 18.3771 7.24379Z"
          fill={isActive ? 'white' : '#f58220'}
        />
        <path
          d="M5.55557 2.22217C8.51854 2.22217 9.7531 4.19748 10 5.18513C10.7408 4.19748 12.8148 2.22217 15.1852 2.22217C18.1482 2.22217 19.2593 4.44439 19.2593 7.03698C19.2593 9.11106 13.0864 15.0617 10 17.7777C7.02801 15.4905 0.992968 9.78605 0.740757 6.29624C0.633968 4.81861 1.85187 2.22217 5.55557 2.22217Z"
          fill={isActive ? 'white' : ''}
        />
      </g>
    </svg>
  );
};

LikeSvg.defaultProps = {
  isActive: false,
};

export default LikeSvg;
